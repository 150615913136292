<template>
  <v-container
    fluid
    class="my-md-12 px-md-6 mx-xl-auto"
    style="max-width: 1600px"
  >
    <v-row>
      <v-col>
        <h2 class="text-md-h4 text--secondary">
          {{ $t("title.notifications") }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Notifications are used by either COUNTER or individual content
          providers to inform clients about important events related to a
          particular SUSHI service. These include notifications of service
          maintenance, data issues requiring data re-harvest, etc.
        </p>
        <p>
          The following list contains all registered notifications regardless of
          platform. You can also find notifications related to a particular
          platform listed on its corresponding page.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NotificationList
          :notifications="notifications"
          show-platform
          show-release
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationList from "../components/NotificationList";
import CounterAPI from "../lib/api";
export default {
  name: "NotificationsPage",

  components: { NotificationList },

  data() {
    return {
      notifications: [],
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
    };
  },

  methods: {
    async getNotifications() {
      this.notifications = await this.api.getAllNotifications();
    },
  },

  mounted() {
    this.getNotifications();
  },
};
</script>

<style scoped></style>
